<template>
  <div class="container pd col-start-center">
    <headBar
      :title="$store.state.type ? '虎鹿瘦喜 新年FLAG' : '超鹿健康资料'"
      left-arrow
      @click-left="newAppBack"
    />
    <img class="banner" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/banner.png" alt="">
    <!-- 一个选择块 -->
    <div class="check-content">
      <h2 class="title f36">您每月愿意在健身上投入多少？</h2>
      <ul class="check-ul f28 row-start-center van-row--flex">
        <li :class="['check-item--single', 'row-start-center', checkedList.includes(item.code) ? 'checked fw6' : '']" v-for="item in list" :key="item.code" @click="choose(item.code)">
          <div>
            <p class="f28 dark">{{item.title}}</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="button-box fixed">
      <div class="cl-button f32 fw5 row-center-center" @click="goNext">{{ $store.state.flagJson.type ? '提交资料，去立FLAG！' : '提交资料' }}</div>
    </div>
  </div>
</template>

<script>
import { newAppBack } from '@/lib/appMethod'
import { getList } from './index'
import headBar from '@/components/app/headBar'
import userMixin from '@/mixin/userMixin'
import { getParam } from '@/lib/utils'
export default {
  mixins: [userMixin],
  components: {
    headBar,
  },
  data() {
    return {
      list: [],
      checkedList: [],
    }
  },
  methods: {
    newAppBack,
    choose(code, max) {
      if (max) {
        // 多选 最多max项
        let index = this.checkedList.indexOf(code)
        if (index > -1) {
          this.checkedList.splice(index, 1)
        } else {
          this.checkedList.push(code)
          if (this.checkedList.length > max) {
            this.checkedList.shift()
          }
        }
      } else {
        // 单选
        this.checkedList = []
        this.checkedList.push(code)
      }

    },
    // 提交资料
    submitData() {
      let session = JSON.parse(sessionStorage.getItem('flagJson'))
      let keys = Object.keys(session)
      let arr = []
      keys.map((item) => {
        if (item !== 'type' && item !== 'giftId') {
          if (['height', 'weight', 'birthday'].includes(item)) {
            arr.push({type: item, value: session[item]})
          } else if (item !== 'type'){
            arr.push({type: item, codes: session[item]})
          }
        }
      })

      let submitObj = {
        detail: arr,
        giftId: session.giftId,
        userId: this.userId,
        token: this.token
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios.post(this.baseURLApp + '/user/health/data/info/save', submitObj).then(() => {
        this.$toast.clear()
      })
    },
    async goNext() {
      if (this.checkedList.length === 0) {
        this.$toast({
          message: '请选择',
          duration: 3000
        });
        return
      }
      this.$store.commit('setFlagJson', {fitnessInvestment: this.checkedList})

      await this.submitData()

      if(this.$store.state.flagJson.type){ // 虎年flag
        this.$router.replace('page-data')

      } else { // 生日
        // 如果有这个id 表示从新人权益页面来的 调用解锁新人权益接口
        const userNewcomerPowerRegularId = sessionStorage.getItem('userNewcomerPowerRegularId')
        console.log('sss', userNewcomerPowerRegularId);
        if (userNewcomerPowerRegularId) {
          this.$axios.post(this.baseURLApp + '/app/newcomerPower/unLockCampGift', {userId: this.userId, userNewcomerPowerRegularId: userNewcomerPowerRegularId}).then(() => {
            this.$toast('填写完成')
            setTimeout(() => {
              this.$router.replace('/superdeer-activity/new-people')
            }, 2000);
          })
        } else {
          this.$router.replace('page-success')
        }
      }
      this.countPoint('43','311','1485')
    }
  },
  async created() {
    await this.$getAllInfo()
    let obj = await getList(this.userId, this.token, 'fitnessInvestment')

    this.checkedList = obj.codes
    this.list = obj.list
    this.countPoint('43','311','1484')
  },
}
</script>
<style lang="less" scoped>
@import url(./index.less);
.container {
  background-color: #F9FAFB;
}
</style>
